import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import * as React from "react";
import {ButtonGroup} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import {useEffect, useState} from "react";
import ApiDataProvider from "../sercices/ApiDataProvider";
import AlertDialog from "./AlertDialog";

export function Question(props) {

    let [questionNumber, setQuestionNumber] = useState(props.questionNumber);
    let [questionData, setQuestionData] = useState(null);
    let [selectedReply, setSelectedReply] = useState(null);
    let [isBackDialogOpen, setIsBackDialogOpen] = useState(false);

    useEffect(() => {
        if (!questionData) {
            getDataForQuestion(questionNumber)
        }
    })

    function getDataForQuestion(questionNumber) {
        ApiDataProvider.getQuestionData(props.sessionId, questionNumber, (data) => {
            setQuestionData(data)
            ApiDataProvider.getPreviousReply(props.sessionId, questionNumber, (dataPreviousReply) => {
                if (dataPreviousReply) dataPreviousReply.map(reply => {
                    setSelectedReply(reply.id)
                    return reply;
                })
            });
        });
    }

    function onClickNext() {
        if (questionNumber < props.questionsCount) {
            if (selectedReply) {
                ApiDataProvider.saveReply(props.sessionId, questionNumber, selectedReply, () => {
                    let nextQuestionNumber = questionNumber + 1;
                    setQuestionNumber(nextQuestionNumber)
                    props.onChangeQuestionNumber(nextQuestionNumber)
                    getDataForQuestion(nextQuestionNumber)
                });
            } else {
                let nextQuestionNumber = questionNumber + 1;
                setQuestionNumber(nextQuestionNumber)
                props.onChangeQuestionNumber(nextQuestionNumber)
                getDataForQuestion(nextQuestionNumber)
            }
        } else {
            if (selectedReply) {
                ApiDataProvider.saveReply(props.sessionId, questionNumber, selectedReply, () => {
                    props.onQuestionEnd();
                });
            } else {
                props.onQuestionEnd();
            }
        }

    }

    function onBackDialogClose() {
        setIsBackDialogOpen(false)
    }

    function onBackDialogDisgree() {
        setIsBackDialogOpen(false)

    }

    function onBackDialogAgree() {
        setIsBackDialogOpen(false)
        props.onQuestionPreviousToLanguage()
    }

    function onClickBack() {
        if (questionNumber > 1) {
            let previousQuestionNumber = questionNumber - 1;
            setQuestionNumber(previousQuestionNumber)
            props.onChangeQuestionNumber(previousQuestionNumber)
            getDataForQuestion(previousQuestionNumber)
        } else {
            setIsBackDialogOpen(true)
        }
    }

    function setReply(e) {
        setSelectedReply(e.target.value)
        return true;
    }


    function onClickEnd() {
        if (selectedReply) {
            ApiDataProvider.saveReply(props.sessionId, questionNumber, selectedReply, () => {
                props.onQuestionEnd();
            });
        } else {
            props.onQuestionEnd();
        }
    }

    return (
        <Box sx={{textAlign: 'center'}}>
            <h1>Pytanie {questionNumber}</h1>
            {questionData && <h2>{questionData.content}</h2>}
            <FormControl>
                {questionData &&
                <RadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={selectedReply}
                    onChange={setReply}
                >
                    {questionData.questionReplies.map(item => {
                        return (
                            <FormControlLabel key={item.id} value={item.id} control={<Radio/>} label={item.content}/>)
                    })}
                </RadioGroup>
                }

                <br/>

                <ButtonGroup variant="outlined" aria-label="outlined button group">
                    <Button onClick={onClickBack} variant="" disableElevation>
                        Wstecz
                    </Button>

                    <Button onClick={onClickNext} variant="contained" disableElevation>
                        Dalej
                    </Button>

                </ButtonGroup>
                <br/>
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                    <Button onClick={onClickEnd} variant="" disableElevation>
                        Zakończ i sprawdź test
                    </Button>
                </ButtonGroup>


            </FormControl>

            <LinearProgress sx={{marginTop: 4}} variant="determinate"
                            value={(questionNumber / props.questionsCount * 100)}/>

            <AlertDialog
                open={isBackDialogOpen}
                title="Czy na pewno chcesz przerwać test?"
                onAgree={onBackDialogAgree}
                onDisagree={onBackDialogDisgree}
                onClose={onBackDialogClose}
            >
                Jeśli wrócisz do listy wyboru języka, Twój test zostanie przerwany.<br/>
                Będzie można rozpocząć test od nowa. <br/>Wszystkie poprzednie odpowiedzi zostaną utracone.
            </AlertDialog>

        </Box>
    );
}