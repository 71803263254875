import './App.css';
import MyAppBar from "./components/MyAppBar";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {LanguageChooser} from "./components/LanguageChooser";
import {Question} from "./components/Question";
import {Resume} from "./components/Resume";
import {useEffect, useState} from "react";
import ApiDataProvider from "./sercices/ApiDataProvider";
import {End} from "./components/End";
import AlertDialog from "./components/AlertDialog";
import * as React from "react";


function App() {

    let [mode, setMode] = useState('language');
    let [language, setLanguage] = useState(null);
    let [sessionId, setSessionId] = useState(null);
    let [questionNumber, setQuestionNumber] = useState(1);
    let [emailAddress, setEmailAddress] = useState('');
    let [phoneNumber, setPhoneNumber] = useState('');
    let [questionsCount, setQuestionsCount] = useState(0);
    let [isResumeDialogOpen, setIsresumeDialogOpen] = useState(false);
    // let [isResumeDialogLock, setIsResumeDialogLock] = useState(false);

    let storLanguage = localStorage.getItem('language')
    let storSessionId = localStorage.getItem('sessionId')
    let storQuestionNumber = parseInt(localStorage.getItem('questionNumber'))
    let storQuestionsCount = parseInt(localStorage.getItem('questionsCount'))

    useEffect(() => {
        if (storLanguage && storSessionId && storQuestionNumber && storQuestionsCount) {
            setIsresumeDialogOpen(true);
        }
    }, [storLanguage, storSessionId, storQuestionNumber, storQuestionsCount])

    let onResumeDialogAgree = function () {
        setIsresumeDialogOpen(false);
        setLanguage(storLanguage)
        setSessionId(storSessionId)
        setQuestionNumber(storQuestionNumber)
        setQuestionsCount(storQuestionsCount)
        setMode('question')
        // setIsResumeDialogLock(true)
    }
    let onResumeDialogDisgree = function () {
        localStorage.clear()
        setIsresumeDialogOpen(false);
        // setIsResumeDialogLock(true)
    }
    let onResumeDialogClose = function () {
        localStorage.clear()
        setIsresumeDialogOpen(false);
        // setIsResumeDialogLock(true)
    }

    let changeEmail = function(emailAddress) {
        setEmailAddress(emailAddress)
    }
    let changePhone = function(phoneNumber) {
        setPhoneNumber(phoneNumber)
    }
    let changeQuestionNumber = function(questionNumber) {
        localStorage.setItem('questionNumber', questionNumber)
        setQuestionNumber(questionNumber)
    }
    let startSession = function(languageId) {
        setLanguage(languageId)
        ApiDataProvider.startSession(languageId, (sessionId, questionsCount) => {
            setSessionId(sessionId)
            localStorage.setItem('sessionId', sessionId)
            setQuestionsCount(questionsCount)
            localStorage.setItem('questionsCount', questionsCount)
            localStorage.setItem('language', languageId)
            setMode('question')
        })
    }

    function onQuestionEnd() {
        setMode('resume')
    }
    function onQuestionPreviousToLanguage() {
        localStorage.clear()
        setIsresumeDialogOpen(false);
        setMode('language')
    }
    function onPreviousToQuestions() {
        setMode('question')
    }
    function onEndSession(emailAddress, phoneNumber) {
        ApiDataProvider.endSession(sessionId, emailAddress, phoneNumber, () => {
            localStorage.clear()
            setMode('end')
        })
    }

    return (
      <div>
      <MyAppBar/>
      <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 1,
              width: '100%',
                maxWidth: 900,
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingBottom: 4
            },
          }}
      >
          <Paper elevation={0}>
              { mode === 'language' && <LanguageChooser startSession={startSession} />}
              { mode === 'question' && <Question questionNumber={questionNumber} language={language} sessionId={sessionId} questionsCount={questionsCount} onChangeQuestionNumber={changeQuestionNumber} onQuestionEnd={onQuestionEnd} onQuestionPreviousToLanguage={onQuestionPreviousToLanguage} />}
              { mode === 'resume' && <Resume email={emailAddress} phone={phoneNumber} onChangeEmail={changeEmail} onChangePhone={changePhone} sessionId={sessionId} onEndSession={onEndSession} onPreviousToQuestions={onPreviousToQuestions} />}
              { mode === 'end' && <End />}
          </Paper>
      </Box>
          <AlertDialog
              open={mode === 'language' && isResumeDialogOpen}
              title="Czy chcesz wznowić poprzedni test?"
              onAgree={onResumeDialogAgree}
              onDisagree={onResumeDialogDisgree}
              onClose={onResumeDialogClose}
          >
              Wykonywanie testu zostało przerwane. Możesz wznowić wykonywanie testu.<br/>
              Jeśli nie wznowisz testu, będziesz mógł rozpocząć nowy. Utracisz jednak wszystkie poprzednie odpowiedzi.<br/>
              Czy wznowić poprzedni test?
          </AlertDialog>
      </div>
  );
}

export default App;
